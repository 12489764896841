// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"
import PostsDisplay from "../components/posts-display"


type Data = {
  site: {
    siteMetadata: {
      title: string
      sections: Array<string>
    }
  }
  allMdx: {
    edges: {
      node: {
        excerpt: string
        frontmatter: {
          title: string
          date: string
          description: string
        }
        fields: {
          slug: string
        }
      }
    }[]
  }
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title
  const sections = data.site.siteMetadata.sections
  const posts = data.allMdx.edges
  return (
    <Layout pathname={location.pathname} title={siteTitle}>
      <SEO title="All posts" />
      <Bio title={siteTitle}/>
      <Nav />
      <PostsDisplay posts={posts} showImage={true}/>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        sections {
          title
          description
        }
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            image: featured {
              childImageSharp {
                fixed(width: 630) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
