/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { rhythm } from "../utils/typography"

import "../styles/bio.css"

const Bio = ({title}) => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/logo-photo.png/" }) {
        childImageSharp {
          fixed(width: 24, height: 24) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata
  return (
    <div
    style={{
        display: `flex`,
        flexDirection: `row`,
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '20px',
        marginTop: '20px'
      }}
    >
      <div 
      className={"bio"}
      style={{
        display: `flex`,
        flexDirection: `row`,
        alignItems: 'center',
        justifyContent: 'space-between',
        
      }}>
        <div>
        <div
      style={{
        display: `flex`,
        flexDirection: `row`,
        alignItems: 'center',
      }}
      >
      <h1
        style={{
          marginTop: 0,
          marginBottom: 0,
        }}
      >
          {title}
      </h1><Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author.name}
        style={{
          marginLeft: rhythm(1 / 2),
          marginBottom: 0,
        }}
        imgStyle={{
        }}
      />
      </div>
      <div
      >
  
      <p>
      A casual introduction to causal inference for business analytics, by {' '}<a href="/about">Ken Acquah</a>
      </p>
      </div>
      
      </div>
      
      </div>
      <div
      style={{
        display: `flex`,
        flexDirection: `column`,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
 
     
      </div>
    </div>
  )
}

export default Bio
