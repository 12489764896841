
import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import logoVector from '../../content/assets/logo-vector.svg';

import  "../styles/nav.css"

const Nav = ({image}) => {
  
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            sections {
              title
              description
            }
          }
        }
      }
    `
  )
return (
  <div>{ image &&
    <div
      style={{
        display: 'flex',
        flexDirection: `row`,
        alignItems: `center`,
        justifyContent: `center`,
      }}
    >
      <Link
      style={{
        boxShadow: `none`,
        color: `inherit`,
      }}
      to={`/`}
    >
      <img
        src={logoVector}
        alt={"causalflows-logo"}
        style={{
          width: 50,
          height: 50,
         
        }}
      />
    </Link>
    </div>
    }

    <div
    style={{
        display: `flex`,
        flexDirection: `row`,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom:'20px',
        marginTop:'0px',
        marginLeft:'-10px',
      }}
    >
      <div
      className={'nav-button-container'}
      >
      {site.siteMetadata.sections.map((section) =>{
          return (
            <button onClick={() => {
              window.location.href = `/${section.title.toLowerCase()}`;
            }} className={"nav-button"}>
                {section.title}
              </button>
          )
      })}
      </div>
      <button onClick={() => {
          window.location.href = 'https://causalflows.substack.com';
        }} className={"ripple-button"}>
          Subscribe
        </button>
        </div>
    </div>
    
  )
}

export default Nav
