import React from "react"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography"
import Image from "gatsby-image"

import "../styles/posts-display.css"

const PostsDisplay = ({ posts, showImage}) => {
    
    return posts
        .filter(({ node }) => node.fields.slug !== '/about/')
        .map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
                <article key={node.fields.slug} className={"post-display"}>
                    {showImage &&
                        <Image
                            objectFit="cover"
                            objectPosition="50% 0%"
                            fixed={node.frontmatter.image.childImageSharp.fixed}
                            style={{
                                marginBottom: 0,
                                marginTop: 10,
                                maxHeight: 300,
                                maxWidth:'100%'
                            }}

                        />
                    }
                    <div >
                        <header>
                            <h3
                                style={{
                                    marginTop: rhythm(1 / 2),
                                    marginBottom: rhythm(1 / 4),
                                
                                }}
                            >
                                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                                    {title}
                                </Link>
                            </h3>
                            <small>{node.frontmatter.date}</small>
                        </header>
                        <section >
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: node.frontmatter.description || node.excerpt,
                                }}
                            />
                        </section>
                    </div>
                </article>
            )
        })
}

export default PostsDisplay